<template>
  <div class="caseDetail">
    <PageTitle ttl-en="CASE" ttl-jp="_想定課題" />
    <div class="inner">
      <p class="caseDetail__mv js-scroll">
        <img src="/genbashiko/img/case/case03/mv.jpg" alt="" />
      </p>
      <section class="caseDetail__outline">
        <h1 class="js-scroll">
          エンジンなど複雑な形状のワークで
          <br />影が発生して不良の見落としが発生
        </h1>
        <p class="caseDetail__outline__txt js-scroll">
          エンジンやシリンダーヘッドのような凹凸が多く複雑な形状のものを検査する工程では、通常のLEDや蛍光灯のように点や線で発光するタイプの光源を使用して、ワークに照明を当てると、どうしても影が発生してしまうため、不良の見落としにつながります。また、影を解消するためにワークを何度も動かす必要も生じて、時間と手間がかかるだけではなく、作業者の疲労による作業効率の低下などを招きます。
        </p>
        <div class="caseDetail__outline__cate js-scroll">
          <p>担当領域:</p>
          <ul>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: '既存の検査場を改修したい' }}"
              >
                既存の検査場を改修したい
              </router-link>
            </li>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: 'エンジン・パワートレイン工程' }}"
              >
                エンジン・パワートレイン工程
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=現場調査">
                現場調査
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=施策策定">
                施策策定
              </router-link>
            </li>
            <li>
              <router-link
                class="category"
                to="/case?category=コンサルティング"
              >
                コンサルティング
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=製品開発">
                製品開発
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=納品後サポート">
                納品後サポート
              </router-link>
            </li>
          </ul>
        </div>
      </section>
      <section class="caseDetail__env">
        <h3 class="caseDetail__env__ttl js-scroll">
          想定される環境
        </h3>
        <ul class="js-scroll">
          <li>シリンダーヘッドやコンロッドなどの検査台</li>
          <li>エンジンブロックなどで使用される対象ワークの可動検査台</li>
          <li>ハンディライトを併用している</li>
        </ul>
      </section>
      <section class="caseDetail__ex">
        <h3 class="caseDetail__ex__ttl js-scroll">
          想定課題と具体的な改善施策例
        </h3>
        <div class="caseDetail__ex__problem js-scroll">
          <div class="caseDetail__ex__problem__p">
            <p class="ttl">
              課題
            </p>
            <p class="txt">
              影が出てしまい不良の見落としが発生・ワークの移動に手間と時間がかかる
            </p>
          </div>
          <div class="caseDetail__ex__problem__a">
            <span class="ttl">施策</span>
            <p class="txt">
              影が出ない無影灯を導入
            </p>
          </div>
          <div class="caseDetail__ex__problem__img">
            <p>
              <img src="/genbashiko/img/case/case03/image01.jpg" alt="" />
            </p>
            <p>
              <img src="/genbashiko/img/case/case03/image02.jpg" alt="" />
            </p>
          </div>
          <p class="caseDetail__ex__problem__cap">
            影が出ないように、発光面が広い無影灯の「ルーチ・リタープ」を設置することで、検査スペースをほぼ均一な明るさで照らすことが可能になり、影を解消することができます。それによって見落としを減らして直行率を高めたり、照射面に明暗差が生じやすい、複雑な形状のワークを頻繁に動かす手間が省けます。しかも目に優しい光なので作業効率を維持できます。
          </p>
        </div>
        <div class="caseDetail__ex__movie js-scroll">
          <div class="caseDetail__ex__movie__block">
            <p class="caseDetail__ex__movie__ttl">
              ▼【日本語版】商品ムービー
            </p>
            <div class="caseDetail__ex__movie__iframeWrap">
              <iframe
                src="https://www.youtube.com/embed/SS78S9EyGpE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div class="caseDetail__ex__movie__block">
            <p class="caseDetail__ex__movie__ttl">
              ▼【海外版】商品ムービー
            </p>
            <div class="caseDetail__ex__movie__iframeWrap">
              <iframe
                src="https://www.youtube.com/embed/n6SAx8gHQ6M"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
        <p class="caseDetail__ex__download js-scroll">
          <a href="/genbashiko/upload/download_pdf/PA-3-1.pdf" target="_blank"
            >課題に関する資料ダウンロード</a
          >
        </p>
      </section>
    </div>
  </div>
</template>
<script>
import PageTitle from '@/components/PageTitle.vue'

export default {
  components: {
    PageTitle
  },
  head: {
    title: {
      inner: 'Case03'
    },
    meta: [
      { property: 'og:title', content: 'Case03｜GENBA SHIKO' },
      {
        name: 'description',
        content:
          'エンジンなど複雑な形状のものを検査する工程で、影の発生や不良の見落としを減らしたいなら、影が出ない無影灯を導入で作業効率をサポートします。'
      },
      {
        property: 'og:description',
        content:
          'エンジンなど複雑な形状のものを検査する工程で、影の発生や不良の見落としを減らしたいなら、影が出ない無影灯を導入で作業効率をサポートします。'
      },
      {
        property: 'og:url',
        content: 'https://luci.co.jp/genbashiko/case/case03'
      },
      { property: 'og:type', content: 'article' }
    ]
  }
}
</script>
